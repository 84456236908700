import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

const AppPrivacyPolicy = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // Navigates back to the previous page
    };

    return (
        <div className="bg-white px-6 py-28 lg:px-8">
            <div className="mx-auto max-w-5xl text-base leading-7 text-gray-700">
                <button
                    onClick={handleBackClick}
                    className="flex pb-6 items-center text-gray-700 hover:text-gray-900"
                >
                    <ChevronLeftIcon className="w-5 h-5 mr-2" />
                    Back
                </button>
                <h1 className="text-5xl font-bold tracking-tight text-gray-900">Privacy Policy</h1>
                <div className="mt-10 pb-10">
                    <p className="pb-6">
                        MG Aero (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy
                        explains how we collect, use, disclose, and safeguard your information when you use our website
                        and services. By using our platform, you agree to the practices described in this policy.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">1. Information We Collect</h2>
                    <p className="pb-6">
                        We may collect the following types of information:
                        <ul className="list-disc ml-6">
                            <li><strong>Personal Information:</strong> Name, email address, contact details, and other information provided during registration.</li>
                            <li><strong>Usage Data:</strong> Information about how you use our platform, including IP addresses, browser type, and pages visited.</li>
                            <li><strong>Technical Data:</strong> Device information, operating system, and other technical details necessary for providing our services.</li>
                        </ul>
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">2. How We Use Your Information</h2>
                    <p className="pb-6">
                        We use the information we collect for the following purposes:
                        <ul className="list-disc ml-6">
                            <li>To provide, manage, and improve our services.</li>
                            <li>To communicate with you regarding updates, promotions, and customer support.</li>
                            <li>To comply with legal obligations and resolve disputes.</li>
                        </ul>
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">3. Sharing of Information</h2>
                    <p className="pb-6">
                        We do not sell or rent your data. However, we may share your data with:
                        <ul className="list-disc ml-6">
                            <li><strong>Service Providers:</strong> Third-party vendors who assist in our operations (e.g., hosting, analytics, payment processing).</li>
                            <li><strong>Legal Obligations:</strong> Authorities if required by law or to protect our legal rights.</li>
                        </ul>
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">4. Data Protection</h2>
                    <p className="pb-6">
                        We implement technical and organizational measures to secure your data against unauthorized
                        access, alteration, disclosure, or destruction. While we strive to protect your data, no method
                        of transmission over the internet or electronic storage is 100% secure.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">5. Your Rights</h2>
                    <p className="pb-6">
                        You have the following rights under applicable data protection laws:
                        <ul className="list-disc ml-6">
                            <li><strong>Access:</strong> Request details of the information we hold about you.</li>
                            <li><strong>Correction:</strong> Request corrections to your personal data.</li>
                            <li><strong>Deletion:</strong> Request the deletion of your personal data.</li>
                            <li><strong>Data Portability:</strong> Request a copy of your data in a structured, machine-readable format.</li>
                            <li><strong>Objection:</strong> Object to the processing of your data for specific purposes.</li>
                        </ul>
                        To exercise these rights, please contact us at <a href="mailto:info@mgaero.eu" className="text-indigo-600">info@mgaero.eu</a>.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">6. International Data Transfers</h2>
                    <p className="pb-6">
                        Your information may be transferred to and maintained on servers located outside your country.
                        We ensure that such transfers comply with applicable data protection laws, including GDPR.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">7. Cookies and Tracking Technologies</h2>
                    <p className="pb-6">
                        We use cookies and similar technologies to enhance user experience and analyze site usage. You
                        can manage your cookie preferences in your browser settings.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">8. Retention of Data</h2>
                    <p className="pb-6">
                        We retain your data for as long as necessary to fulfill the purposes described in this policy or
                        as required by law. When data is no longer needed, we securely delete or anonymize it.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">9. Changes to This Policy</h2>
                    <p className="pb-6">
                        We may update this Privacy Policy periodically. Any changes will be posted on this page with an
                        updated effective date. Continued use of the platform after such changes constitutes your
                        agreement to the updated terms.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">10. Contact Us</h2>
                    <p className="pb-6">
                        If you have any questions or concerns about this Privacy Policy or our data practices, please
                        contact us at:
                        <br />
                        <strong>Email:</strong> <a href="mailto:info@mgaero.eu" className="text-indigo-600">info@mgaero.eu</a>
                        <br />
                        <strong>Address:</strong> MG Invest sp. z o.o., Orkana 20F / 5, Rabka-Zdrój 34-700, Poland
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AppPrivacyPolicy;
