import React, { useEffect, useState } from 'react';
import { Flight } from '../../../types';
import { Subheading } from '../../../catalystui/heading';
import {PlusIcon} from "@heroicons/react/16/solid";
import {CheckIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../../catalystui/dialog";
import {DialogBackdrop, DialogPanel} from "@headlessui/react";
import {Field, Label} from "../../../catalystui/fieldset";
import {Input} from "../../../catalystui/input";
import {Button} from "../../../catalystui/button";

interface ExtraInformationSectionProps {
    formValues: Flight;
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const ExtraInformationSection: React.FC<ExtraInformationSectionProps> = ({
                                                                             formValues,
                                                                             handleChange,
                                                                         }) => {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Backend API URL
    const API_BASE_URL = "https://mgaero.eu:8443/api/v1";

    // Fetch images securely
    useEffect(() => {
        const fetchImages = async () => {
            if (!formValues.images || formValues.images.length === 0) return;

            const token = sessionStorage.getItem('token');
            if (!token) {
                console.error('No token found in sessionStorage');
                return;
            }

            try {
                const imageFetchPromises = formValues.images.map(async (filename) => {
                    const response = await fetch(`${API_BASE_URL}/getImage/protected_images/${filename}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (!response.ok) {
                        throw new Error(`Failed to fetch image: ${filename}`);
                    }
                    const blob = await response.blob();
                    return URL.createObjectURL(blob);
                });

                const urls = await Promise.all(imageFetchPromises);
                setImageUrls(urls);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [formValues.images]);

    const openModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setIsModalOpen(false);
    };

    return (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-4">
            <div className="px-4 sm:px-0">
                <Subheading>Extra information</Subheading>
            </div>
            <div
                className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3 dark:bg-zinc-900 dark:ring-zinc-600">
                <div className="px-4 py-4 sm:px-8 sm:py-8">
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
                        {/* Remarks Section */}
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="remarks"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                            >
                                Remarks
                            </label>
                            <div className="mt-2">
                                <textarea
                                    rows={3}
                                    name="remarks"
                                    id="remarks"
                                    value={formValues.remarks}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-zinc-900 dark:ring-zinc-600 dark:text-gray-100"
                                />
                            </div>
                        </div>

                        {/* Images Section */}
                        {imageUrls.length > 0 && (
                            <div className="sm:col-span-2 mt-4">
                                <label
                                    htmlFor="images"
                                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                                >
                                    Attachments
                                </label>
                                <div className="mt-2 grid grid-cols-2 gap-4">
                                    {imageUrls.map((url, index) => (
                                        <div
                                            key={index}
                                            className="relative h-48 w-full rounded-md overflow-hidden shadow-md border border-gray-300 dark:border-zinc-600"
                                        >
                                            <img
                                                src={url}
                                                alt={`Flight Image ${index + 1}`}
                                                className="h-full w-full object-cover cursor-pointer"
                                                onClick={() => openModal(url)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Full-size Image Modal */}
            {isModalOpen && selectedImage && (
                <Dialog size="5xl" open={isModalOpen} onClose={setIsModalOpen}>
                    <DialogBody className="place-items-center">
                        <img src={selectedImage} alt="Full-size Flight"/>
                    </DialogBody>
                    <DialogActions>
                        <Button plain onClick={() => setIsModalOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default ExtraInformationSection;
