import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

const AppTermsOfUse = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // Navigates back to the previous page
    };

    return (
        <div className="bg-white px-6 py-28 lg:px-8">
            <div className="mx-auto max-w-5xl text-base leading-7 text-gray-700">
                <button
                    onClick={handleBackClick}
                    className="flex pb-6 items-center text-gray-700 hover:text-gray-900"
                >
                    <ChevronLeftIcon className="w-5 h-5 mr-2" />
                    Back
                </button>
                <h1 className="text-5xl font-bold tracking-tight text-gray-900">Terms of Use</h1>
                <div className="mt-10 pb-10">
                    <p className="pb-6">
                        Welcome to MG Aero! These Terms of Use outline the rules and regulations for using our platform.
                        By signing up or using our services, you agree to comply with these terms. Please read them
                        carefully.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">1. Eligibility</h2>
                    <p className="pb-6">
                        You must be at least 18 years old or have the legal capacity to enter into agreements to use
                        this platform. By creating an account, you confirm that all information provided during
                        registration is accurate and truthful.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">2. Account Responsibilities</h2>
                    <p className="pb-6">
                        Users are responsible for maintaining the confidentiality of their login credentials and for all
                        activities that occur under their account. Notify us immediately if you suspect unauthorized
                        access to your account.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">3. Prohibited Activities</h2>
                    <p className="pb-6">
                        You agree not to:
                        <ul className="list-disc ml-6">
                            <li>Use the platform for illegal or unauthorized purposes.</li>
                            <li>Interfere with or disrupt the platform’s operation or security.</li>
                            <li>Attempt to gain unauthorized access to user accounts or data.</li>
                            <li>Engage in activities that violate the rights of others, including intellectual property.</li>
                        </ul>
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">4. Data Usage and Privacy</h2>
                    <p className="pb-6">
                        Your use of this platform is governed by our Privacy Policy, which describes how we collect,
                        use, and protect your personal data. By agreeing to these Terms of Use, you also consent to the
                        data practices described in our Privacy Policy.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">5. Services Provided</h2>
                    <p className="pb-6">
                        Our platform offers tools and features for aviation enthusiasts, pilots, and organizations,
                        including but not limited to:
                        <ul className="list-disc ml-6">
                            <li>User registration and profile management.</li>
                            <li>Access to aviation-related resources and data.</li>
                            <li>Flight management tools.</li>
                            <li>Integration with partner services, where applicable.</li>
                        </ul>
                        We strive to ensure uninterrupted availability of our services but cannot guarantee it.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">6. Intellectual Property</h2>
                    <p className="pb-6">
                        All content, trademarks, and software on this platform are the property of MG Aero or its
                        licensors. Unauthorized copying, reproduction, or distribution of this content is prohibited.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">7. Termination of Service</h2>
                    <p className="pb-6">
                        We reserve the right to suspend or terminate your account at our discretion for any reason,
                        including violation of these terms or any fraudulent or harmful activity.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">8. Limitation of Liability</h2>
                    <p className="pb-6">
                        To the fullest extent permitted by law, MG Aero is not liable for any direct, indirect,
                        incidental, or consequential damages arising from the use of our platform, including but not
                        limited to errors, interruptions, or loss of data.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">9. Amendments</h2>
                    <p className="pb-6">
                        We may update these Terms of Use from time to time. Changes will be posted on this page, and the
                        updated terms will apply upon posting. Continued use of the platform constitutes your acceptance
                        of the updated terms.
                    </p>

                    <h2 className="my-6 text-xl font-bold text-gray-900">10. Contact Information</h2>
                    <p className="pb-6">
                        For any questions or concerns regarding these Terms of Use, please contact us at
                        <a href="mailto:info@mgaero.eu" className="text-indigo-600"> info@mgaero.eu</a>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AppTermsOfUse;
