// src/components/Auth/RegisterScreen.tsx

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import apiClient from "../../tools/AxiosInterceptor";
import {XCircleIcon} from '@heroicons/react/20/solid';
import {Heading} from "../../catalystui/heading";
import {Field, FieldGroup, Fieldset, Label} from "../../catalystui/fieldset";
import {Input} from "../../catalystui/input";
import {Button} from "../../catalystui/button";
import {Text, TextLink} from "../../catalystui/text";

interface RegisterResponse {
    message: string;
}

const RegisterScreen: React.FC = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);
        setIsLoading(true);

        const formData = new FormData(e.currentTarget);
        const data = {
            email: (formData.get("email") as string)?.trim() || "",
            first_name: (formData.get("first_name") as string)?.trim() || "",
            last_name: (formData.get("last_name") as string)?.trim() || "",
            password: (formData.get("password") as string)?.trim() || "",
            // Add additional fields as necessary
        };

        // Basic client-side validation
        if (!data.email || !data.first_name || !data.last_name || !data.password) {
            setErrorMessage("All fields are required.");
            setIsLoading(false);
            return;
        }

        try {
            const response = await apiClient.post<RegisterResponse>("/register", data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200 && response.data) {
                setSuccessMessage("Registration successful! Please check your email to verify your account.");
                // Optionally, redirect to login after a delay
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            } else {
                setErrorMessage("An unexpected error occurred. Please try again.");
                console.error("Unexpected response:", response);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("Registration failed. Please try again.");
            }
            console.error("Error during the registration process", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <Heading className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
                    Create a new account
                </Heading>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit} noValidate>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label htmlFor="email">Email</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="first_name">First Name</Label>
                                <Input
                                    id="first_name"
                                    name="first_name"
                                    type="text"
                                    autoComplete="given-name"
                                    required
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="last_name">Last Name</Label>
                                <Input
                                    id="last_name"
                                    name="last_name"
                                    type="text"
                                    autoComplete="family-name"
                                    required
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="password">Password</Label>
                                <Input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                />
                            </Field>
                            {/* Add additional fields as necessary */}
                            {errorMessage && (
                                <div className="rounded-md bg-red-50 p-2">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {successMessage && (
                                <div className="rounded-md bg-green-50 p-2">
                                    <div className="flex">
                                        {/* You can add an icon here for success */}
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-green-800">{successMessage}</h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Button type="submit" className="w-full" disabled={isLoading}>
                                {isLoading ? "Registering..." : "Sign up"}
                            </Button>
                        </FieldGroup>
                    </Fieldset>
                </form>
                <Text className="mt-6 text-sm text-gray-600">
                    Already have an account?{' '}
                    <TextLink href="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Sign in
                    </TextLink>
                </Text>
            </div>
        </div>
    )
};

export default RegisterScreen;
