import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://mgaero.eu:8443/api',
    // other configurations
});

// Response Interceptor
apiClient.interceptors.response.use(
    response => response,
    error => {
        console.log('API Error Response:', error.response);

        if (error.response) {
            const { status } = error.response;

            if (status === 401 || status === 403) {
                // Optionally, you can clear any stored tokens here
                // sessionStorage.removeItem('token');
                window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;
