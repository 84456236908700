import React, {useState, useEffect} from 'react';
import {User, Rating, PilotDetails, Organization} from '../../../types';
import {Input} from '../../../catalystui/input';
import {Button} from '../../../catalystui/button';
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from '../../../catalystui/dialog';
import {Description, ErrorMessage, Field, FieldGroup, Fieldset, Label, Legend} from '../../../catalystui/fieldset';
import {Listbox, ListboxLabel, ListboxOption} from "../../../catalystui/listbox";
import {XCircleIcon} from "@heroicons/react/20/solid";
import {Checkbox, CheckboxField, CheckboxGroup} from "../../../catalystui/checkbox";
import {Text} from "../../../catalystui/text"

interface UserFormProps {
    isOpen: boolean;
    userData?: User;
    onSubmit: (formValues: Partial<User>) => Promise<void>;
    onClose: () => void;
    errorMessage?: string | null;
    accessibleOrganizations: Organization[];
}

const UserForm: React.FC<UserFormProps> = ({
                                               isOpen,
                                               userData,
                                               onSubmit,
                                               onClose,
                                               errorMessage,
                                               accessibleOrganizations
                                           }) => {
    const selectedOrg = sessionStorage.getItem('selectedOrg');
    const selectedOrgId = selectedOrg ? JSON.parse(selectedOrg).id : '';
    const defaultPilot: PilotDetails = {
        pilot_license_number: '',
        medical: {
            medicalClass: '',
            validTill: '',
        },
        ratings: [],
    };

    const initialFormValues: Partial<User> = {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        phoneNumber: '',
        role: 'user',
        organizationIds: [],
        pilot: {
            pilot_license_number: '',
            medical: {
                medicalClass: '',
                validTill: '',
            },
            ratings: [{ratingName: '', validTill: ''}],
        },
    };

    const [formValues, setFormValues] = useState<Partial<User>>(initialFormValues);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const validate = (): boolean => {
        const newErrors: { [key: string]: string } = {};

        // Validate First Name
        if (!formValues.first_name || formValues.first_name.trim() === '') {
            newErrors.first_name = 'First name is required.';
        }

        // Validate Last Name
        if (!formValues.last_name || formValues.last_name.trim() === '') {
            newErrors.last_name = 'Last name is required.';
        }

        // Validate Username
        if (!formValues.username || formValues.username.trim() === '') {
            newErrors.username = 'Username is required.';
        }

        // Validate Email
        if (!formValues.email || formValues.email.trim() === '') {
            newErrors.email = 'Email is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValues.email)) {
            newErrors.email = 'Invalid email address.';
        }

        // Validate Role
        if (!formValues.role || formValues.role.trim() === '') {
            newErrors.role = 'Role is required.';
        }

        // Validate Password (only if adding a new user)
        if (!userData) {
            if (!formValues.password || formValues.password.trim() === '') {
                newErrors.password = 'Password is required.';
            } else if (formValues.password.length < 8) {
                newErrors.password = 'Password must be at least 8 characters long.';
            }
        }

        // Validate Organizations
        if (!formValues.organizationIds || formValues.organizationIds.length === 0) {
            newErrors.organizationIds = 'At least one organization must be selected.';
        }

        setErrors(newErrors);

        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    };

    const handleBlur = (field: string) => {
        const fieldErrors: { [key: string]: string } = {};

        switch (field) {
            case 'first_name':
                if (!formValues.first_name || formValues.first_name.trim() === '') {
                    fieldErrors.first_name = 'First name is required.';
                }
                break;
            case 'last_name':
                if (!formValues.last_name || formValues.last_name.trim() === '') {
                    fieldErrors.last_name = 'Last name is required.';
                }
                break;
            case 'username':
                if (!formValues.username || formValues.username.trim() === '') {
                    fieldErrors.username = 'Username is required.';
                }
                break;
            case 'email':
                if (!formValues.email || formValues.email.trim() === '') {
                    fieldErrors.email = 'Email is required.';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValues.email)) {
                    fieldErrors.email = 'Invalid email address.';
                }
                break;
            case 'role':
                if (!formValues.role || formValues.role.trim() === '') {
                    fieldErrors.role = 'Role is required.';
                }
                break;
            case 'password':
                if (!userData) {
                    if (!formValues.password || formValues.password.trim() === '') {
                        fieldErrors.password = 'Password is required.';
                    } else if (formValues.password.length < 6) {
                        fieldErrors.password = 'Password must be at least 6 characters long.';
                    }
                }
                break;
            // Add more cases for other fields as needed
            default:
                break;
        }

        setErrors((prevErrors) => ({...prevErrors, ...fieldErrors}));
    };

    useEffect(() => {
        if (userData) {
            setFormValues({
                ...userData,
                // Exclude the password field when editing
                password: undefined,
                organizationIds: userData.organizationIds || [],
                pilot: userData.pilot
                    ? {
                        ...userData.pilot,
                        pilot_license_number: userData.pilot.pilot_license_number || '',
                        medical: userData.pilot.medical
                            ? {
                                ...userData.pilot.medical,
                                medicalClass: userData.pilot.medical.medicalClass || '',
                                validTill: userData.pilot.medical.validTill || '',
                            }
                            : {
                                medicalClass: '',
                                validTill: '',
                            },
                        ratings: userData.pilot.ratings || [{ratingName: '', validTill: ''}],
                    }
                    : {
                        pilot_license_number: '',
                        medical: {
                            medicalClass: '',
                            validTill: '',
                        },
                        ratings: [{ratingName: '', validTill: ''}],
                    },
            });
        } else {
            setFormValues({
                ...initialFormValues,
                organizationIds: [selectedOrgId],
                password: '',
            });
        }
    }, [userData]);

    const handleOrganizationChange = (orgId: string, isChecked: boolean) => {
        if (isChecked) {
            setFormValues((prev) => ({
                ...prev,
                organizationIds: [...(prev.organizationIds || []), orgId],
            }));
        } else {
            setFormValues((prev) => ({
                ...prev,
                organizationIds: prev.organizationIds?.filter(id => id !== orgId),
            }));
        }

        // Validation: Ensure at least one organization is selected
        if (formValues.organizationIds && formValues.organizationIds.length > 0) {
            setErrors((prev) => {
                const { organizationIds, ...rest } = prev;
                return rest;
            });
        } else {
            setErrors((prev) => ({
                ...prev,
                organizationIds: 'At least one organization must be selected.',
            }));
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, path?: string[]) => {
        const {name, value} = e.target;

        setFormValues((prev) => {
            if (!path) {
                return {...prev, [name]: value};
            }

            const updatedNested = {...prev};
            let current: any = updatedNested;

            for (let i = 0; i < path.length - 1; i++) {
                if (current[path[i]] == null) {
                    current[path[i]] = {};
                }
                current = current[path[i]];
            }

            current[path[path.length - 1]] = value;

            return updatedNested;
        });
    };

    const handleRatingChange = (index: number, key: keyof Rating, value: string) => {
        setFormValues((prev) => {
            const pilot = prev.pilot || defaultPilot;
            return {
                ...prev,
                pilot: {
                    ...pilot,
                    ratings: pilot.ratings.map((rating, i) => (i === index ? {...rating, [key]: value} : rating)),
                },
            };
        });
    };

    const handleAddRating = () => {
        setFormValues((prev) => {
            const pilot = prev.pilot || defaultPilot;
            return {
                ...prev,
                pilot: {
                    ...pilot,
                    ratings: [...pilot.ratings, {ratingName: '', validTill: ''}],
                },
            };
        });
    };

    const handleDeleteRating = (index: number) => {
        setFormValues((prev) => {
            const pilot = prev.pilot || defaultPilot;
            return {
                ...prev,
                pilot: {
                    ...pilot,
                    ratings: pilot.ratings.filter((_, i) => i !== index),
                },
            };
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Perform validation
        const isValid = validate();

        if (!isValid) {
            // If validation fails, do not proceed with submission
            return;
        }

        const submissionData = {...formValues};

        if (!submissionData.organizationIds || submissionData.organizationIds.length === 0) {
            setErrors((prev) => ({ ...prev, organizationIds: 'At least one organization must be selected.' }));
            return;
        }

        // Remove password if it's undefined or empty
        if (!submissionData.password || submissionData.password.trim() === '') {
            delete submissionData.password;
        }

        try {
            // Submit the form data via parent
            await onSubmit(submissionData);
            // Optionally, reset the form or provide success feedback
        } catch (error) {
            // Handle submission errors if not handled by the parent
            console.error('Form submission error:', error);
        }
    };

    return (
        <Dialog size="5xl" open={isOpen} onClose={onClose}>
            <DialogTitle>{userData ? 'Edit User' : 'Add User'}</DialogTitle>
            <DialogDescription>
                {userData ? 'Update the user details below.' : 'Fill out the details to add a new user.'}
            </DialogDescription>
            {errorMessage && (
                <div className="rounded-md my-2 bg-red-50 dark:bg-red-800 p-2">
                    <div className="flex">
                        <div className="shrink-0">
                            <XCircleIcon aria-hidden="true" className="size-5 text-red-400 dark:text-red-100"/>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800 dark:text-red-100">{errorMessage}</h3>
                        </div>
                    </div>
                </div>
            )}
            <DialogBody>
                <form onSubmit={handleSubmit} className="space-y-6">

                    <Fieldset>
                        <FieldGroup>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <Field>
                                    <Label>First Name</Label>
                                    <Input
                                        name="first_name"
                                        value={formValues.first_name || ''}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={() => handleBlur('first_name')}
                                        required
                                        invalid={!!errors.first_name}
                                    />
                                    {errors.first_name && <ErrorMessage>{errors.first_name}</ErrorMessage>}
                                </Field>
                                <Field>
                                    <Label>Last Name</Label>
                                    <Input
                                        name="last_name"
                                        value={formValues.last_name || ''}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={() => handleBlur('last_name')}
                                        required
                                        invalid={!!errors.last_name}
                                    />
                                    {errors.last_name && <ErrorMessage>{errors.last_name}</ErrorMessage>}
                                </Field>
                                <Field>
                                    <Label>Username</Label>
                                    <Input
                                        name="username"
                                        value={formValues.username || ''}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={() => handleBlur('username')}
                                        required
                                        invalid={!!errors.username}
                                    />
                                    {errors.username && <ErrorMessage>{errors.username}</ErrorMessage>}
                                </Field>
                                <Field>
                                    <Label>Email</Label>
                                    <Input
                                        name="email"
                                        type="email"
                                        value={formValues.email || ''}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={() => handleBlur('email')}
                                        required
                                        invalid={!!errors.email}
                                    />
                                    {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                                </Field>
                                <Field>
                                    <Label>Phone Number</Label>
                                    <Input
                                        name="phoneNumber"
                                        type="tel"
                                        value={formValues.phoneNumber || ''}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={() => handleBlur('phoneNumber')}
                                        invalid={!!errors.phoneNumber}
                                    />
                                    {errors.phoneNumber && <ErrorMessage>{errors.phoneNumber}</ErrorMessage>}
                                </Field>
                                <Field>
                                    <Label>Role</Label>
                                    <Listbox
                                        name="role"
                                        value={formValues.role || 'user'}
                                        onChange={(value: string) => {
                                            setFormValues((prev) => ({...prev, role: value}));
                                            // Optionally, validate on change
                                            if (value.trim() === '') {
                                                setErrors((prev) => ({...prev, role: 'Role is required.'}));
                                            } else {
                                                setErrors((prev) => {
                                                    const {role, ...rest} = prev;
                                                    return rest;
                                                });
                                            }
                                        }}
                                        invalid={!!errors.role}
                                    >
                                        <ListboxOption value="user">
                                            <ListboxLabel>User</ListboxLabel>
                                        </ListboxOption>
                                        <ListboxOption value="admin">
                                            <ListboxLabel>Admin</ListboxLabel>
                                        </ListboxOption>
                                    </Listbox>
                                    {errors.role && <ErrorMessage>{errors.role}</ErrorMessage>}
                                </Field>
                                <Fieldset>
                                    <Legend>Organizations</Legend>
                                    <Text>Select one or more organizations that the user has access to.</Text>
                                    <CheckboxGroup>
                                        {accessibleOrganizations.map((org) => (
                                            <CheckboxField key={org.id}>
                                                <Checkbox
                                                    name="organizationIds"
                                                    value={org.id}
                                                    checked={formValues.organizationIds?.includes(org.id) || false}
                                                    onChange={(checked: boolean) => handleOrganizationChange(org.id, checked)}
                                                />
                                                <Label>{org.name}</Label>
                                                {/* Optional: Add a Description if available */}
                                                {/* <Description>{org.description}</Description> */}
                                            </CheckboxField>
                                        ))}
                                    </CheckboxGroup>
                                    {errors.organizationIds && <ErrorMessage>{errors.organizationIds}</ErrorMessage>}
                                </Fieldset>
                                {!userData && (
                                    <Field>
                                        <Label>Password</Label>
                                        <Input
                                            name="password"
                                            type="password"
                                            value={formValues.password || ''}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={() => handleBlur('password')}
                                            required
                                            invalid={!!errors.password}
                                        />
                                        {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
                                    </Field>
                                )}
                            </div>

                            {/* Pilot Details */}
                            <div className="grid grid-cols-1 gap-8 sm:grid-cols-3 sm:gap-4">
                                <Field>
                                    <Label>Pilot License Number</Label>
                                    <Input
                                        name="pilot_license_number"
                                        value={formValues.pilot?.pilot_license_number || ''}
                                        onChange={(e) => handleChange(e, ['pilot', 'pilot_license_number'])}
                                        onBlur={() => handleBlur('pilot_license_number')}
                                        invalid={!!errors.pilot_license_number}
                                    />
                                    {errors.pilot_license_number &&
                                        <ErrorMessage>{errors.pilot_license_number}</ErrorMessage>}
                                </Field>
                                <Field>
                                    <Label>Medical Class</Label>
                                    <Input
                                        name="medicalClass"
                                        value={formValues.pilot?.medical?.medicalClass || ''}
                                        onChange={(e) => handleChange(e, ['pilot', 'medical', 'medicalClass'])}
                                        onBlur={() => handleBlur('medicalClass')}
                                        invalid={!!errors.medicalClass}
                                    />
                                    {errors.medicalClass && <ErrorMessage>{errors.medicalClass}</ErrorMessage>}
                                </Field>
                                <Field>
                                    <Label>Medical Valid Till</Label>
                                    <Input
                                        type="date"
                                        name="medicalValidTill"
                                        value={formValues.pilot?.medical?.validTill || ''}
                                        onChange={(e) => handleChange(e, ['pilot', 'medical', 'validTill'])}
                                        onBlur={() => handleBlur('medicalValidTill')}
                                        invalid={!!errors.medicalValidTill}
                                    />
                                    {errors.medicalValidTill && <ErrorMessage>{errors.medicalValidTill}</ErrorMessage>}
                                </Field>
                            </div>
                        </FieldGroup>
                    </Fieldset>
                    <Fieldset>
                        <Legend>Ratings</Legend>
                        {formValues.pilot?.ratings?.map((rating, index) => (
                            <FieldGroup key={index}>
                                <div className="grid grid-cols-1 gap-8 sm:grid-cols-6 sm:gap-4">
                                    <Field>
                                        <Input
                                            name={`ratingName-${index}`}
                                            value={rating.ratingName}
                                            onChange={(e) =>
                                                handleRatingChange(index, 'ratingName', e.target.value)
                                            }
                                            onBlur={() => handleBlur(`ratingName-${index}`)}
                                            placeholder="Rating Name"
                                            invalid={!!errors[`ratingName-${index}`]}
                                        />
                                        {errors[`ratingName-${index}`] &&
                                            <ErrorMessage>{errors[`ratingName-${index}`]}</ErrorMessage>}
                                    </Field>
                                    <Field>
                                        <Input
                                            type="date"
                                            name={`validTill-${index}`}
                                            value={rating.validTill}
                                            onChange={(e) => handleRatingChange(index, 'validTill', e.target.value)}
                                            onBlur={() => handleBlur(`validTill-${index}`)}
                                            placeholder="Valid Till"
                                            invalid={!!errors[`validTill-${index}`]}
                                        />
                                        {errors[`validTill-${index}`] &&
                                            <ErrorMessage>{errors[`validTill-${index}`]}</ErrorMessage>}
                                    </Field>
                                    <Button
                                        color="red"
                                        type="button"
                                        onClick={() => handleDeleteRating(index)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </FieldGroup>
                        ))}
                        {errors.ratings && <ErrorMessage>{errors.ratings}</ErrorMessage>}
                        <Button className="mt-4" type="button" onClick={handleAddRating}>
                            Add Rating
                        </Button>
                    </Fieldset>
                </form>
            </DialogBody>
            <DialogActions>
                <Button plain onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit}>{userData ? 'Update User' : 'Add User'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserForm;
