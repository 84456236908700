import { useState, useEffect } from 'react';
import apiClient from '../tools/AxiosInterceptor'; // Adjust the path as necessary
import { Organization } from '../types'; // Define the Organization type accordingly

const useOrganizations = () => {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const orgIds = sessionStorage.getItem('organizationIds',);
                if (!orgIds) {
                    throw new Error('No organization IDs found.');
                }
                const parsedOrgIds: string[] = JSON.parse(orgIds);
                const response = await apiClient.get<Organization[]>('/v1/getOrganizations', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                    params: { ids: parsedOrgIds.join(',') }, // Ensure IDs are comma-separated
                });
                setOrganizations(response.data);
            } catch (err: any) {
                setError(err.response?.data?.message || 'Failed to fetch organizations.');
            } finally {
                setLoading(false);
            }
        };

        fetchOrganizations();
    }, []);

    return { organizations, loading, error };
};

export default useOrganizations;
