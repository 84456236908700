import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import apiClient from "../../tools/AxiosInterceptor";
import {XCircleIcon} from '@heroicons/react/20/solid'
import {Heading} from "../../catalystui/heading";
import {Field, FieldGroup, Fieldset, Label} from "../../catalystui/fieldset";
import {Input} from "../../catalystui/input";
import {Button} from "../../catalystui/button";
import {Text} from "../../catalystui/text"
import {TextLink} from "../../catalystui/text";

interface LoginResponse {
    token: string;
    role: string;
    uuid: string;
    email: string;
    organizationIds: string[]; // Corrected to string array
}

interface LocationState {
    from?: {
        pathname: string;
    };
}

const LoginScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false); // Added loading state

    // Define the expected shape of location.state
    const state = location.state as LocationState;
    const from = state?.from?.pathname || "/dashboard/flights";

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);
        setIsLoading(true); // Start loading

        const formData = new FormData(e.currentTarget);
        const data = {
            login: (formData.get("login") as string)?.trim() || "",
            password: (formData.get("password") as string)?.trim() || ""
        };

        // Basic client-side validation
        if (!data.login || !data.password) {
            setErrorMessage("Please enter both login and password.");
            setIsLoading(false);
            return;
        }

        try {
            const response = await apiClient.post<LoginResponse>("/login", data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200 && response.data) {
                console.log(response.data);
                sessionStorage.setItem('token', response.data.token); // Store the token
                sessionStorage.setItem('user_role', response.data.role); // Store the user's role
                sessionStorage.setItem('user_uuid', response.data.uuid); // Store the user's UUID
                sessionStorage.setItem('email', response.data.email); // Store the user's email
                sessionStorage.setItem('organizationIds', JSON.stringify(response.data.organizationIds)); // Ensure valid JSON
                navigate(from, {replace: true});
            } else {
                // Handle unexpected status codes
                setErrorMessage("An unexpected error occurred. Please try again.");
                console.error("Unexpected response:", response);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("Invalid login or password.");
            }
            console.error("Error during the login process", error);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <Heading className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
                    Sign in to your account
                </Heading>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>Login</Label>
                                <Input id="login"
                                       name="login"
                                       type="text"
                                       autoComplete="login"
                                       required/>
                            </Field>
                            <Field>
                                <Label>Password</Label>
                                <Input id="password"
                                       name="password"
                                       type="password"
                                       autoComplete="current-password"
                                       required/>
                            </Field>
                            {errorMessage && (
                                <div className="rounded-md bg-red-50 p-2">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <Button type="submit" className="w-full" disabled={isLoading}>
                                {isLoading ? "Signing in..." : "Sign in"}
                            </Button>
                        </FieldGroup>
                    </Fieldset>
                </form>
                <Text className="mt-6 text-sm text-gray-600">
                    Don’t have an account?{' '}
                    <TextLink href="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Sign up
                    </TextLink>
                </Text>

            </div>
        </div>
    )
};

export default LoginScreen;
